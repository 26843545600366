import React from "react";
import "./scientific_commission.scss";

const ScientificCommission = () => {
  return (
    <div className="scientificCommission">
      <div className="titulo-container">
        <div className="titulo"> Comissão Científica </div>
      </div>
      <div className="members">
        <div className="member">Arlindo Frederico Junior</div>
        <div className="member">Ana Paula Carrijo</div>
        <div className="member">Evelyn Sonobe</div>
        <div className="member">Jorge Esteves</div>
        <div className="member">Lélia Fernandes</div>
        <div className="member">Lígia Castegnaro Trevisan</div>
        <div className="member">Miriam May Philippi</div>
        <div className="member">Suely Grosseman</div>
        <div className="member">Trícia Feitosa</div>
        <div className="member">Valéria Romano</div>
      </div>
    </div>
  );
};

export default ScientificCommission;
