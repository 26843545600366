import React from "react";
import "./contributors.scss";

const Contributors = () => {
  return (
    <div className="contributors">
      <div className="titulo-container">
        <div className="titulo"> Beneméritos </div>
      </div>
      <div className="members">
        <div className="member"> Alice Polomeni</div>
        <div className="member">Jorge Brandão</div>
        <div className="member">Abram Eksterman (póstumo)</div>
        <div className="member">Julio de Mello Filho (póstumo)</div>
      </div>
    </div>
  );
};

export default Contributors;
