import React from "react";
//import PropTypes from 'prop-types';
import "./third_header.scss";
import Button from "../../input/button/button.js";

const ThirdHeader = ({ className }) => {
  return (  <div className="Header-third">
    <div className="row"> 
      <div className="titulo">WebBalint em tempos de pandemia</div>

    <div className="content-third-header">

        <div className="col-1">

            <div className="texto">
            Em 2021 a Associação Brasileira de Balint retoma a oferta de grupos Balint virtuais abertos à toda à comunidade balintiana, participantes experientes ou iniciantes e demais pessoas interessadas em ter uma vivência desse método. 
            </div>
            <div className="texto">
            Os Grupos Balint se propõem a discutir a relação profissional de saúde - paciente. A cada grupo é apresentado um caso que tenha sido impactante ou que na hora do grupo surja o desejo de compartilhar, sempre com foco no aspecto relacional. Após a apresentação do caso, o grupo começa a trabalhar o caso pela associação livre de ideias e todo participante pode trazer casos.            </div>
        
        </div>
  <div className="col-2">
    <div className="texto"> Os grupos serão realizados pelo aplicativo zoom, com cerca de 1h de duração e frequência quinzenal a partir de março em diversos horários, conforme a disponibilidade e conveniência de cada um, conduzido por dois facilitadores pela ABRABALINT.</div>
    <div className="texto">Será necessário um ambiente calmo e tranquilo, sem interferências externas já que a confidencialidade é muito importante; sugerimos a utilização de um computador para melhor experiência de grupo. </div>
    <div className="texto"> Nessa primeira etapa faremos 6 encontros; e para viabilizar o trabalho sugerimos a contribuição por encontro de R$ 50,00 para não associados e R$30,00 para sócios associados. </div>
     <a target="_blank" href="https://forms.gle/gesA2rLN14YaTtMr6">
        <Button text="Inscreva-se" />
        </a>
    </div>  
  </div>
  </div>
</div>
  
  );
};

export default ThirdHeader;
