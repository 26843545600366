import React from "react";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Repository from './pages/repository/repository';
import HomePage from './pages/home_page/home_page';
import News from './pages/news/news';
import Guidelines from "./pages/guidelines/guidelines";


const Routes = () => (
    <Router>
        <Switch>
            <Route exact={true} path="/">
                <HomePage />
            </Route>
            <Route   exact={true} path="/noticias">
                <News />
            </Route>     
            <Route   exact={true} path="/biblioteca">
                <Repository />
            </Route>    
            <Route   exact={true} path="/diretrizes">
                <Guidelines />
            </Route>     
        </Switch>
    </Router>
);

export default Routes;
