import React from "react";
import "./about_us.scss";

const AboutUs = () => {
  <script language="JavaScript">
    document.writeln(screen.width + " x " + screen.height)
  </script>;

  return (
    <div className="aboutUs">
      <div>
        <div className="right-triangle" />
        <div className="content">
          <div className="column">
            <div className="title-container">
              <div className="title">Balint em poucas palavras</div>
            </div>
            <div className="first-text">
              Abram Eksterman define o Grupo Balint como uma "reunião uni ou
              multiprofissional destinada a diagnosticar e elaborar tensões
              irracionais que perturbam a tarefa assistencial de seu(s)
              executor(es), pervertendo ou impedindo a sua realização de forma
              eficaz e adequada" e com objetivos assistenciais e educacionais.
            </div>
          </div>
          <div className="column">
            <div className="text">
              Na década de 50 Michael Balint e Enid Balint criaram os grupos com
              os médicos generalistas - General Practitioners (GP) – com o
              intuito de estudar e refletir a relação médico-paciente, sobretudo
              os aspectos de transferência e contratransferência, pois
              considerava que “a personalidade, os sentimentos e as reações do
              médico constituem uma chave diagnóstica e um instrumento
              terapêutico”.
            </div>
            <div className="text">
              Posteriormente o método, já batizado de Grupo Balint, expandiu-se
              para as demais profissões de saúde pelo seu potencial em estimular
              o contato com as emoções, o reconhecimento de habilidades
              interpessoais e a compreensão
            </div>
          </div>
          <div className="column">
            <div className="last-text">
              de seus limites e ser uma prática de prevenção do Burnout. Por
              meio de um caso disparador o apresentador do caso divide com o
              grupo suas inquietações acerca da sua relação com um paciente e os
              demais integrantes discutem o caso, num movimento de associação
              livre permitindo uma compreensão, até então inconsciente, de
              fenômenos relacionais.
            </div>
            <div className="last-text-last">
              Grupo Balint é portanto um grupo de reflexão, que se propõe a
              melhorar a qualidade das relações entre os profissionais de Saúde
              e seus pacientes.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
