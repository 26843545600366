import React from "react";
import Slider from "infinite-react-carousel";
import FirstEvent from "../../events/first_event/first_event";
import SecondEvent from "../../events/second_event/second_event";
import ThirdEvent from "../../events/third_event/third_event";
import FourthEvent from "../../events/fourth_event/fourth_event";

const EventsSlider = () => {
  return (
    <Slider autoplay dots autoplaySpeed="7000" arrows={false}>
      <div>
        <FirstEvent />
      </div>
      <div>
        <SecondEvent />
      </div>
      <div>
        <ThirdEvent />
      </div>
      <div>
        <FourthEvent />
      </div>
    </Slider>
  );
};

export default EventsSlider;
