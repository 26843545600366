import React from "react";
import {BrowserRouter as Router, Route, Switch, useHistory} from "react-router-dom";
import "./navBar.scss";

const NavBar = ({ className }) => {

  let history = useHistory();

  const navigateHome = () => {
      history.push("/");
    }

    const navigateLibrary = () => {
      history.push("/biblioteca");
    }

    const navigateNews = () => {
      history.push("/noticias");
    }

    const navigateGuidelines = () => {
      history.push("/diretrizes");
    }


  return (
    <div  className="NavBar">
      <div className="col-1">
        <div  onClick={navigateHome} className="logo"></div>
      </div>
      <div className="col-2">
        <div className="options">
        <div   onClick={navigateNews} className="option" >
            Notícias
          </div>  
          <div   onClick={navigateLibrary} className="option" >
            Publicações
          </div>   
          <div   onClick={navigateGuidelines} className="option" >
            Diretrizes
          </div>                       
          <div  onClick={navigateHome} className="option" >
            Página Inicial
          </div>
       
        </div>
      </div>
    </div>
  );
  
};

export default NavBar;
