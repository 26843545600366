import React from "react";
import "./first_event.scss";

const FirstEvent = () => {
  return (
    <div className="events">
      <div className="content">
        <div className="column-left">
          <div className="title-container">
            <div className="title">Eventos realizados</div>
          </div>
          <div className="event-title">
            I Encontro de Aperfeiçoamento em Liderança de Grupos Balint com o
            Professor André Mathalon (Universidade de Telaviv)
          </div>
          <div className="address">São Paulo </div>
          <div className="date">3 a 4 de Fevereiro de 2020</div>
        </div>
        <div className="column-right">
          <div className="image1" />
        </div>
      </div>
    </div>
  );
};

export default FirstEvent;
