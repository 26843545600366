import React from 'react';
import {BrowserRouter as Router, Route, Switch, useHistory} from "react-router-dom";
import Button from "../input/button/button.js";
import "./library.scss";

const Library = () => {     

    let history = useHistory();

    const navigate = () => {
        history.push("/biblioteca");
      }

    return (
    <div className="Library">
      <div className="left-rectangle"> </div>
      <div className="right-triangle"> </div>
      <div className="center-container">
        <div className="title">Conheça nossa biblioteca de artigos</div>   
          <Button text="Veja mais" onClick={navigate}/>   
      </div>
    </div>
  );
};

export default Library;
