import React from "react";
import Slider from "infinite-react-carousel";
import Direction from "../../contributors/direction/direction";
import ScientificCommission from "../../contributors/scientific_commission/scientific_commission";
import Contributors from "../../contributors/benemerits_and_postumants/contributors";
import "./../slider.scss";

const ContributorsSlider = () => {
  return (
    <Slider autoplay dots autoplaySpeed="5000" arrows={false}>
      <div>
        <Direction />
      </div>
      <div>
        <ScientificCommission />
      </div>
      <div>
        <Contributors />
      </div>
    </Slider>
  );
};

export default ContributorsSlider;
