import React from "react";
import NavBar from "../../components/navBar/navBar";
import AboutUs from "../../components/about_us/about_us";
import Footer from "../../components/footer/footer";
import OurHistory from "../../components/our_history/our_history";
import Library from "../../components/library/library";
import HeaderSlider from "../../components/slider/header_slider/header_slider";
import EventsSlider from "../../components/slider/events_slider/events_slider";
import ContributorsSlider from "../../components/slider/contributors_slider/contributors_slider";
import {useHistory} from 'react-router';

const HomePage = ({ className }) => {

 
  return (
    <div className="homePage">
      <NavBar />
      <HeaderSlider/>
      <AboutUs />
      <ContributorsSlider />
      <OurHistory />
      <EventsSlider />
      <Library/>
      <Footer />
    </div>
  );
};

export default HomePage;
