import React from "react";
import Slider from "infinite-react-carousel";
import Header from "../../header/header_first/header";
import SecondHeader from "../../header/header_second/header";
import ThirdHeader from "../../header/third_header/third_header";
import Button from "../../input/button/button.js";


import "./../slider.scss";

const HeaderSlider = () => {
  return (
    <Slider autoplay dots sautoplaySpeed="6000" arrows={false}>
      <div>
        <Header title="Associação Brasileira de Balint"
          subtitle="Grupo Balint é um grupo de reflexão, que objetiva compartilhar as dificuldades intrínsecas à atividade assistencial, em especial questões complexas que emergem da interação entre profissionais, pacientes e suas famílias."
          button={<a target="_blank" href="https://forms.gle/bQUKQPbs29ttsZNC8">
            <Button text="Associe-se" />
          </a>} />
      </div>
      <div>
        <ThirdHeader />
      </div>

    </Slider>
  );
};

export default HeaderSlider;
