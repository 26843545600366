import React from "react";
import NavBar from "../../components/navBar/navBar";
import Header from "../../components/header/header_first/header";
import Footer from "../../components/footer/footer";
import "./guidelines.scss"


const Guidelines = () => {
  return (
    <div >
      <NavBar/>
      <Header title="Diretrizes" subtitle="Diretrizes da Associação Brasileira de Balint (ABRABALINT) para os níveis de formação de liderança e acreditação de líderes."/>
      <div className="content-news">
          <div className="left-rectangle"></div>
      <div className="title-news">Diretrizes da Associação Brasileira de Balint (ABRABALINT) para os níveis de formação de liderança</div>
           <div className="paragraph">A ABRABALINT estabelece os seguintes níveis de formação em Grupos Balint: </div>
           <div className="paragraph"> <b>Nível 1 – Iniciante: </b> nunca participou de Grupos Balint. </div>
           <div className="paragraph"><b>Nível 2 – Intermediário:</b> teve experiência como participante em pelo menos 10 Grupos Balint e participou de um curso de formação em Liderança de Grupos Balint promovido pela ABRABALINT ou por instituições reconhecidas por ela, com carga horária equivalente. </div>
           <div className="paragraph"><b>Nível 3 – Avançado:</b> teve experiência como participante em pelo menos 10 de Grupos Balint; participou de pelo menos dois cursos de formação em Liderança de Grupos Balint promovido pela ABRABALINT ou por instituições reconhecidas por ela, com carga horária equivalente; teve experiência como colíder ou líder em pelo menos 10 Grupos Balint; e, teve pelo menos 10 encontros de supervisão individual ou em grupo com supervisor acreditado ou reconhecido pela ABRABALINT.  </div>
           <div className="paragraph"><b>Nivel 4 – Formador acreditado:</b> teve experiência como participante em pelo menos 10 Grupos Balint; participou de três cursos de formação em Liderança de Grupos Balint promovido pela ABRABALINT ou por instituições reconhecidas por ela, com carga horária equivalente, teve experiência como colíder ou líder em pelo menos 10 Grupos Balint; teve pelo menos 10 encontros de supervisão individual ou em grupo com supervisor acreditado ou reconhecido pela ABRABALINT; e, foi acreditado pela Comissão de Acreditação da ABRABALINT. </div>
           <div className="paragraph"><b>Nível 5 – Supervisor acreditado:</b> além de todos os requisitos do nível 4, o líder teve pelo menos 20 encontros de supervisão individual ou em grupo com supervisor acreditado ou reconhecido pela ABRABALINT.  </div>

           <div className="paragraph"><b>Nota:</b> As mudanças de nível necessitam de documentos de comprovação e aprovação da Comissão de Acreditação.</div>
           <div className="question">Quando posso começar a conduzir grupos Balint?</div>
        <div className="paragraph"> Recomenda-se iniciar o trabalho como colíder de Grupos Balint a partir do nível 2, com supervisão de líderes acreditados ou reconhecidos pela ABRABALINT para desempenhar essa função.</div>
      </div>
      <div className="content-news">
          <div className="right-rectangle"></div>
      <div className="title-news">Diretrizes da Associação Brasileira de Balint (ABRABALINT) para acreditação de líderes:</div>
           <div className="paragraph">O objetivo do processo de acreditação é certificar líderes/facilitadores de grupos Balint com competência para atuar na formação e supervisão de outros líderes. </div>
           <div className="paragraph"> Processo de acreditação: </div>
           <div className="paragraph">Líderes de grupos Balint que queiram ser acreditados devem apresentar todos os seguintes pré-requisitos:  </div>
           <div className="line">1-	Ser profissional de saúde; </div>
           <div className="line">2-	Ser sócio da ABRABALINT; </div>
           <div className="line">3-	Ter passado pelo nível 4 das diretrizes da formação de liderança para ser reconhecido(a) como Formador </div>
           <div className="line">4-	Ter passado pelo nível 5 das diretrizes da formação de liderança para ser reconhecido(a) como Supervisor(a) </div>
           <div className="line">5- Casos omissos serão analisados pela Comissão Acreditadora.</div>
           <div className="paragraph">Após envio e análise de comprovantes de cada pré-requisito, dois membros da comissão farão a observação da liderança do candidato liderando um grupo e farão uma entrevista posteriormente.</div>
           <div className="paragraph">Após aprovado, o profissional estará apto a participar como formador em cursos de formação de líderes e como supervisor adjunto.  </div>
           <div className="paragraph">Após dois anos como supervisor adjunto e análise da comissão, o acreditado pode ser considerado apto a atuar como supervisor.</div>
           <div className="paragraph"><b>Recomenda-se fortemente trabalho terapêutico pessoal.</b></div>
           <div className="paragraph"><b>Data, 12 de maio de 2021.</b></div>
           <div className="suelySign"/>
           <div className="line"><b>Suely Grosseman</b></div>
           <div className="line">Coordenadora da Comissão de Acreditação</div>
           <div className="chazanSign"/>
           <div className="line"><b>Luiz Fernando Chazan</b></div>
           <div className="line">Presidente</div>
           <div className="paragraph"><b> 
               Marcela Dohms</b></div>
           <div className="line">Vice-presidente</div>





      </div>
    <Footer/>
    </div>
  );
};

export default Guidelines;
