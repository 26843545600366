import React from "react";
import "./third_event.scss";

const ThirdEvent = () => {
  return (
    <div className="events">
      <div className="content">
        <div className="column-left">
          <div className="title-container">
            <div className="title">Eventos realizados</div>
          </div>
          <div className="event-title">
            II Curso de Formação em Lideranças de Grupos Balint com Dr Jorge
            Brandão (Portugal) e Alice Polomeni (França)
          </div>
          <div className="address"> </div>
          <div className="date">Maio de 2018</div>
        </div>
        <div className="column-right">
          <div className="image3" />
        </div>
      </div>
    </div>
  );
};

export default ThirdEvent;
