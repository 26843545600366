import React from "react";
import "./second_event.scss";

const SecondEvent = () => {
  return (
    <div className="events">
      <div className="content">
        <div className="column-left">
          <div className="title-container">
            <div className="title">Eventos realizados</div>
          </div>
          <div className="event-title">
            III Curso de Formação em Lideranças de Grupos Balint com Dr Jorge
            Brandão (Portugal) e Alice Polomeni (França)
          </div>
          <div className="address">Florianópolis </div>
          <div className="date">5 a 7 de Abril de 2019</div>
        </div>
        <div className="column-right">
          <div className="image2" />
        </div>
      </div>
    </div>
  );
};

export default SecondEvent;
