import React from "react";
import "./fourth_event.scss";

const FourthEvent = () => {
  return (
    <div className="events">
      <div className="content4">
        <div className="column-left">
          <div className="title-container">
            <div className="title">Eventos realizados</div>
          </div>
          <div className="event-title">
            I Curso de Formação em Lideranças de Grupos Balint com Dr Jorge
            Brandão (Portugal) e Alice Polomeni (França)
          </div>
          <div className="date">Julho de 2017</div>
        </div>
        <div className="column-right">
          <div className="image4" />
        </div>
      </div>
    </div>
  );
};

export default FourthEvent;
