import React from "react";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="upper">
        <div className="contacts"> 
        <a href="https://www.instagram.com/balintbrasil/?hl=pt-br">
        <div className="contact"> 
         <div className="image-instagram"></div>
        <div className="text-contact">balintbrasil</div
        ></div>
        </a>
        <div className="contact"> 
         <div className="image-gmail"></div>
         <div className="text-contact">abrabalint@gmail.com</div>       
          </div>       
      </div>
      </div>

      <div className="bottom">
        <a href="https://www.linkedin.com/in/analuciacanto/">
          <div className="copyright">© Associação Brasileira de Balint 2023</div>
        </a>
      </div>
    </div>
  );
};

export default Footer;
