import PropTypes from 'prop-types';
import React from 'react';
import './button.scss';

const Button = ({ style, text, onClick, colorTheme, className }) => {
    return (
        <button className={'Button'}
            style={
                colorTheme === 'green' ?
                    { ...style, background: '#00897B' } :
                    { ...style, background: '#EC8E1A' }
            } onClick={onClick}>
            <div className="textButton">{text}</div>
        </button>
    );
};

Button.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func,
    colorTheme: PropTypes.oneOf(['green', 'orange']),
    style: PropTypes.object,
};
Button.defaultProps = {
    className: '',
    text: 'Entrar',
    colorTheme: 'orange',
    style: {},
};

export default Button;
