import React from "react";
import NavBar from "../../components/navBar/navBar";
import Header from "../../components/header/header_first/header";
import Footer from "../../components/footer/footer";
import Button from "../../components/input/button/button.js";
import "./news.scss"

const News = ({ }) => {
  return (
    <div >
      <NavBar />
      <Header title="Notícias" />

      <div className="content-news">
        <div className="left-rectangle-4" />
        <div className="content-text">
          <div className="title-news">ASSEMBLEIA GERAL ORDINÁRIA DA ASSOCIAÇÃO BRASILEIRA DE BALINT 2023</div>
          <div className="title-news"> </div>
          <div className="paragraph">No dia 04 de abril de 2023, os membros da ABRABALINT reuniram-se em
            assembleia para a apresentação do relatório final de gestão 2021-2023 e eleição da diretoria
            da gestão 2023-2025, além da aprovação da contribuição social. </div>

          <div className="paragraph">A plenária elegeu por aclamação de todos a única chapa
            inscrita: Jéssica Leão, Luiz Fernando Chazan, Marianne Sabino, Lélia Fernandes e Arlindo Frederico Junior,
            eleitos como presidente, vice-presidente, secretária, primeira tesoureira e segundo tesoureiro,
            respectivamente. </div>

          <div className="image_100" />
        </div>
      </div>

      <div className="content-news">
        <div className="left-rectangle-3" />
        <div className="content-text">
          <div className="title-news">EDITAL DE CONVOCAÇÃO DA ASSEMBLEIA GERAL ORDINÁRIA DA ASSOCIAÇÃO BRASILEIRA DE BALINT</div>
          <div className="title-news"> </div>
          <div className="paragraph">A diretoria da Associação Brasileira de Balint – ABRABALINT vem, por meio
            desta, convocar seus membros associados para participarem da
            Assembleia Geral Ordinária que será realizada no dia 04/04/2023 às 19h
            (horário de Brasília) através da plataforma de videoconferência ZOOM.
            A assembleia terá como pauta a eleição da nova diretoria da
            Associação para o período de 07/04/2023 a 06/04/2025, além de informes
            gerais. </div>

          <div className="paragraph">A Assembleia sera realizada pelo Zoom (ID da reuniao 885 9494 7334). A
            senha de acesso sera enviado aos associados no dia anterior. </div>

          <div className="paragraph">A diretoria será composta por um Presidente, um Vice-Presidente, um
            Secretário, Primeiro e Segundo Tesoureiros, na forma do artigo 23º do
            estatuto vigente </div>

          <div className="paragraph">As candidaturas deverão ser encaminhadas para o e-mail
            abrabalint@gmail.com até o dia 20/03/2023 e deverão conter a
            nominata completa da chapa, com nome e qualificação dos
            candidatos (nome, profissão, estado civil nacionalidade, CPF, RG e
            endereço completo). </div>

          <div className="paragraph">Para que a candidatura seja homologada a chapa deverá,
            obrigatoriamente, ser composta por membros associados. </div>

          <div className="paragraph">Rio de Janeiro, 03 de março de 2023.</div>

          <div className="data">Luiz Fernando Chazan, Presidente da Associação Brasileira de Balint</div>

        </div>
      </div>

      <div className="content-news">
        <div className="left-rectangle-2" />
        <div className="content-text">
          <div className="title-news">ELEIÇÃO PARA GESTÃO 2021/2023</div>
          <div className="title-news"> </div>
          <div className="paragraph">No dia 06 de abril de 2021 os membros da ABRABALINT reuniram-se em assembleia para a apresentação do relatório final de gestão 2019-2021 e eleição da diretoria e Conselho fiscal da gestão 2021-2023, além da aprovação da contribuição social.  </div>
          <div className="paragraph">A plenária elegeu por aclamação de todos a única chapa inscrita. (Confira abaixo os membros eleitos). Na ocasião,  Luiz Fernando chazan (presidente da ABRABALINT) e gerson Costa Filho (primeiro tesoureiro) falaram com emoção em nome da chapa reeleita, ressaltando as conquistas realizadas pela ABRABALINT no periodo de 2019-2021. Mesmo frente a tantos desafios impostos pela pandemia foram realizados grupos de apoio em saúde mental  a profissionais que estão atendendo na linha de frente e inúmeros grupos de web balints. Neste período a abrabalint também ofertou grupo de estudos para sócios e grupos de supervisão a facilitadores de grupos balint.  </div>
          <div className="paragraph">Ricardo Heinzelmann,  membro da diretoria da SBMFC,  realizou uma fala de felicitação,  reforçando a parceria entre as duas associações e a importância dos grupos balint na formação, seja na graduação ou na residência em MFC.
            Atualmente,  a abrabalint tem investido na constituição do processo de acreditação de líderes/facilitadora de grupos balint e segue ampliando suas ofertas de grupos de web balint.  </div>
          <div className="paragraph"> Luiz Fernando Chazan, Marcela Ceci Dohms, Jéssica Borges Leão, Beatriz Gama Flores e Gerson da Costa Filho, que já estavam como Pres, Vice, Secretária e Segunda e Primeiro tesoureiros, respectivamente </div>
          <div className="paragraph"> Miriam May Phillip, Maria Tavares Cavalcanti e Felipe Souza Vieira às posições de conselheiros fiscais
            Amanda Galvão Oliveira de Almeida, Naarai Camboim Bezerra e Darizon Oliveira Filho para posições de conselheiros suplentes </div>
          <div className="image1" />
        </div>
      </div>

      <div className="content-news">
        <div className="left-rectangle-1" />
        <div className="content-text">
          <div className="title-news">EDITAL DE CONVOCAÇÃO PARA O PROCESSO ELEITORAL DA ASSOCIAÇÃO BRASILEIRA DE BALINT - GESTÃO 2021/2023</div>
          <div className="title-news"> </div>
          <div className="paragraph">A Comissão Eleitoral da Associação Brasileira de Grupos Balint, no uso de suas atribuições, vem convocar a todos os seus membros associados para participarem das eleições para escolha da nova diretoria para a gestão 2021/2023, a ser realizada virtualmente durante Assembleia Geral Ordinária, no dia 06/04/2021 às 19h (horário de Brasília) por meio da plataforma de videoconferência Zoom. </div>
          <div className="paragraph">A inscrição de chapas poderá ser realizada até o dia 20/03/2021 através do e-mail abrabalint@gmail.com. Poderão votar e ser votados todos os associados adimplentes a suas contribuições, conforme artigos 7º e 10º do estatuto social da ABRABALINT. </div>
          <div className="data">Suely Grossman, Ligia Trevisan, Evelyn Sonobe, Comissão eleitoral.</div>
          <div className="buttons-news">
            <div className="button-news"> <a target="_blank" href="https://drive.google.com/file/d/1fdnXVl6le6xvMLM8QJBQsZGlHVs1wZ0J/view">
              <Button text="Veja mais" />
            </a></div>
            <a target="_blank" href="https://drive.google.com/file/d/18gsOqueoSY0bN_xh4lag_TqDa5TZaHhj/view">
              <Button text="Edital" />
            </a></div>

        </div>
      </div>


      <Footer />
    </div>

  );
};

export default News;
