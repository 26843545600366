import React from "react";
import NavBar from "../../components/navBar/navBar";
import Header from "../../components/header/header_first/header";
import Footer from "../../components/footer/footer";
import texts from "./texts.json"
import "./repository.scss"


const Repository = ({ className }) => {
  const data = texts;
  return (
    <div >
      <NavBar/>
      <Header title="Biblioteca de textos" subtitle="Aqui você encontrará, teses, dissertações, textos diversos e links que oferecerão um contato mais aprofundado com o universo Balint. Use sem contraindicações."/>
      <div className="content-repository"> 
      <div className="left-rectangle"> </div> 
      <div className="right-rectangle"> </div> 
      {(data.texts.map((item, index) =><div>
    

      <div className="card">      
          <div className="text-title">{item.title}</div>
          <div className="text-author">{item.author}</div>       
          <a target="_blank" href={item.link}><div className="button-repository">Veja mais</div> </a>
       </div>
  
      </div>

      ))
       }</div>
       <Footer/>
    </div>
  );
};

export default Repository;
