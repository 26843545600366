import React from "react";
import "./direction.scss";

const Direction = () => {
  return (
    <div className="direction">
      <div className="direction-container">
        <div className="left-container">
          <div className="direction-image" />
        </div>
        <div className="right-container">
          <div className="director">
            <div className="director-name">Jéssica Leão</div>
            <div className="director-function">Presidente</div>
          </div>
          <div className="director">
            <div className="director-name">Luiz Fernando Chazan</div>
            <div className="director-function">Vice Presidência</div>
          </div>
          <div className="director">
            <div className="director-name">Marianne Sabino</div>
            <div className="director-function">Secretaria</div>
          </div>
          <div className="director">
            <div className="director-name">Arlindo Frederico Junior</div>
            <div className="director-function">Primeiro Tesoureiro</div>
          </div>
          <div className="director">
            <div className="director-name">Lélia Fernandes</div>
            <div className="director-function">Segunda Tesoureira</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Direction;
