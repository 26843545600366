import React from "react";
import "./our_history.scss";

const OurHistory = () => {
  return (
    <div className="ourHistory">
      <div className="left-triangle" />
      <div className="title-row">
        <div className="title-container">
          <div className="title"> Nossa História </div>
        </div>
      </div>
      <div className="content">
        <div className="image-content">
          <div className="content-left">
            <div className="containerImage">
              <div className="ourHistoryImage"></div>
              <div className="image-description">
                Primeiro Curso de Liderança em Grupos Balint - 2017
              </div>
            </div>
          </div>
          <div className="content-right">
            <div className="containerQuote">
              <div className="quote">
                No Brasil o primeiro grupo que temos registro foi feito por
                Abram Eksterman em 1963 na Santa Casa de Misericórdia do Rio de
                Janeiro, iniciando um processo que perdurou por muitos anos.
              </div>
              <div className="quote">
                Na década de 70, Julio de Mello Filho manteve um grupo Balint no
                Hospital São Francisco de Assis da UFRJ até que começou seu
                trabalho na FCM-UERJ na década de 80, quando os grupos foram
                organizados no Hospital Universitário Pedro Ernesto, já com a
                colaboração de José Roberto Muniz e Luiz Fernando Chazan.
              </div>
            </div>
          </div>
        </div>
        <div className="content-text">
          <div className="column-left">
            <div className="content-column-left">
              <div className="containerText">
                <div className="containerText-left">
                  <div className="paragraph">
                    Grupos se formaram em São Paulo, Santos, Recife, e outras
                    cidades do país. Em Goiânia, a Faculdade de Medicina da
                    Universidade Federal de Goiás introduziu através de Rita
                    Francis Gonzalez Y Rodrigues Branco, os grupos Balint na
                    disciplina de Semiologia Médica durante aproximadamente 12
                    anos e desde agosto de 2006, os alunos do curso de Medicina
                    da Pontifícia Universidade Católica de Goiás têm tido tal
                    experiência. O trabalho de Rita gerou uma dissertação em
                    2001 e uma tese em 2008.
                  </div>
                  <div className="paragraph">
                    Na década de 90 houve uma diminuição de grupos nas
                    instituições hospitalares, mas com o reconhecimento pelo CFM
                    da Medicina de Família e Comunidade em 1986, novos tempos se
                    criaram e os grupos Balint foram redescobertos voltando aos
                    participantes de origem, os médicos de família.
                  </div>
                  <div className="paragraph">
                    No Brasil destacamos a formação na American Balint
                    Association de Suely Grosseman em 2012 que contribui com a
                    formação médica pela sua atuação na Associação Brasileira de
                    Educação Médica.
                  </div>
                </div>
                <div className="containerText-right">
                  <div className="paragraph">
                    No campo do ensino destacou-se por mais de 20 anos o
                    trabalho de Rita Francis Gonzalez Y Rodrigues Branco na
                    Universidade Federal de Goiás contribuindo para a formação
                    na graduação de inúmeros médicos e gerando uma dissertação
                    em 2001 e uma tese em 2008.
                  </div>
                  <div className="paragraph">
                    No Rio de Janeiro é importante destacar a atuação da Médica
                    de Família Ana Paula Borges Carrijo que quando voltou de seu
                    curso na American Balint Association em 2014 iniciou
                    diversos grupos na UFRJ, com o apoio de Valéria Romano e
                    Jorge Esteves, e em sua própria casa aos finais de semana.
                    Este grupo começou a receber diversos profissionais da saúde
                    e foi intitulado Bralint, conectando definitivamente Balint
                    e Brasil.
                  </div>
                  <div className="paragraph">
                    Em 2016, o Rio de Janeiro recebeu o Congresso Mundial de
                    Medicina de Família e Comunidade e o Bralint organizou uma
                    oficina para os participantes, com diversos grupos Balint
                    concomitantes. Neste momento Ana conheceu Alice Polomeni
                    (França) e Jorge Brandão (Portugal).
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column-right">
            <div className="right-rectangle"></div>
            <div className="content-column-right">
              <div className="containerText">
                <div className="paragraph">
                  A partir desse contato, em 2017 Alice e Jorge vieram ao Brasil
                  realizar o Primeiro Curso de Liderança em Grupos Balint.
                </div>
                <div className="paragraph">
                  A partir de então a necessidade de uma Associação Brasileira
                  para fomentar e difundir o Balint no Brasil foi se tornando
                  cada vez mais necessária.
                </div>
                <div className="paragraph">
                  Até que em abril de 2019, no Terceiro Curso de Lideranças em
                  Grupos Balint, na cidade de Florianópolis a ABRABALINT foi
                  fundada.
                </div>
                <div className="paragraph">
                  E no 21st International Balint Congress em setembro de 2019 a
                  Associação Brasileira de Balint foi aceita como integrante da
                  International Balint Federation.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurHistory;
