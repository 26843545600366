import React from "react";
import "./header.scss";

const Header = ({title, subtitle, button}) => {
  return (
    <div className="Header">
      <div className="content">
      <div className="col-1">
        <div className="titulo">{title}</div>
        <div className="subtitulo">{subtitle}</div>
      {button}
      </div>
      <div className="col-2">
        <div className="circle">
          <div className="logo"></div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Header;
